export default function registerCollapseComponents(container = null) {
    const parentDiv = container ?? document;
    const toggleElements = parentDiv.querySelectorAll('.collapse-item');

    // Go through all collapse items + add strapped class to prevent double handlers
    toggleElements.forEach((element) => {
        if (!element.classList.contains('strapped')) {
            element.classList.add('strapped');
            element.querySelector('.collapse-toggle')
                .addEventListener('click', () => {
                    element.classList.toggle('open');
                });
        }
    });
}
